import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd'
import './index.less'

const weeks = [
  { value: '121121', label: '周一' },
  { value: '121122', label: '周二' },
  { value: '121123', label: '周三' },
  { value: '121124', label: '周四' },
  { value: '121125', label: '周五' },
  { value: '121126', label: '周六' },
  { value: '121127', label: '周日' }
]

function TaskWeekNotifyPick({ value, onChange }) {
  const [days, setDays] = useState(value || Dict.map.weeks.map(v => v.value))
  useEffect(() => {
    if (!onChange) {
      return
    }
    onChange(days)
  }, [days])

  return (
    <div className="task-week-notify-pick">
      <Checkbox.Group value={days} onChange={setDays}>
        {Dict.map.weeks.map(v => (
          <Checkbox value={v.value}>{v.label}</Checkbox>
        ))}
      </Checkbox.Group>
    </div>
  )
}

export default TaskWeekNotifyPick
